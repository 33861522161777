// import logo from "./logo.svg";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import "./App.css";
import "./App.scss";
import Logo from "./images/logo12321 1.png";
import { ReactComponent as Rrrow } from "./images/arrow-right.svg";
import CountTo from "./components/CountTo";
import { isElementInViewport, isMobile } from "./utils"
import { produce } from "immer";
import { ReactComponent as More } from './images/more.svg'
import {
  CloseOutlined
} from '@ant-design/icons';

function getItemWidth(container) {
  const [one, two] = container.children
  let rect, rect2
  rect = one.getBoundingClientRect()
  rect2 = two.getBoundingClientRect()
  return rect2.left - rect.left
}

function App() {
  const [left, setLeft] = useState(0)
  const [scrollY, setScrollY] = useState(0);
  const [show, setShow] = useState(false);
  const [itemWidth_1, setItemWidth_1] = useState(0);
  const [itemWidth_2, setItemWidth_2] = useState(0);
  // const [current, setCurrent] = useState(1)
  // const [current_2, setCurrent_2] = useState(1)
  const [current, setCurrent] = useState(null)
  const [current_2, setCurrent_2] = useState(null)
  const our_work = useRef(null);
  const blog = useRef(null);
  const ourWork = useRef(null);
  const [email, setEmail] = useState("")
  const [showCountTo, setShowCountTo] = useState(false)
  const countToEl = useRef(null);
  const [first, setFirst] = useState(true)
  const [text, setText] = useState({
    our_services: {
      list_1: [
        {
          title: "Content\nMarketing",
          desc: "Includes content writing and content strategy to tell your story in a way that drives action.",
        },
        {
          title: "Pay-Per-Click (PPC)\nMarketing",
          desc: "Maximize your reach with tailored ads that connect with the right audience at the right time.",
        },
        {
          title: "Email\nMarketing",
          desc: "Create meaningful connections and drive engagement with personalized emails that speak directly to your audience.",
        },
        {
          title: "Social Media\nMarketing",
          desc: "Engage your audience with meaningful interactions that build loyalty and drive conversions.",
        },
        {
          title: "Market\nResearch",
          desc: "Gain a competitive edge with in-depth Competitive Analysis, SWOT Analysis, and data-backed insights.",
        },
      ],
      list_2: [
        {
          title: "Search Engine\nOptimization (SEO)",
          desc: "Get found by your audience with strategies that put you at the top of search results.",
        },
        {
          title: "Brand\nMarketing",
          desc: "From print to experiential, we craft strategies that resonate and engage, helping you build a brand that sticks.",
        },
        {
          title: "Crisis\nCommunication",
          desc: "Stay prepared for the unexpected with a plan that protects your brand's reputation.",
        },
        {
          title: "Customer\nEngagement",
          desc: "Build stronger relationships with strategies that keep your audience engaged and invested in your brand.",
        },
        {
          title: "Marketing\nAnalytics",
          desc: "Transform data into actionable insights that drive smarter marketing decisions and measurable results.",
        },
      ],
    },
    step: [
      {
        title: "Capture",
        desc: "Reach beyond the ordinary. Expand your audience with campaigns designed to connect—right place, right time.",
      },
      {
        title: "Inspire",
        desc: "Make your message matter. Engage prospects with tailored storytelling that captivates and resonates on a personal level.",
      },
      {
        title: "Build Trust",
        desc: "Earn more than attention—build trust. Showcase your credibility with reputation management that strengthens your brand's voice through authentic proof.",
      },
      {
        title: "Drive Action",
        desc: "Turn interest into commitment. From first click to final purchase, our strategies guide your prospects toward the finish line, driving conversions that matter.",
      },
      {
        title: "Nurture & Grow",
        desc: "Transform customers into champions. Foster lasting relationships, inspire loyalty, and elevate lifetime value through smart re-engagement and personalized upselling.",
      },
    ],
    our_work: [
      {
        image: require("./images/1108 (5).png"),
        image_mobile: require("./images/1108mobile (8).png"),
        title: "Pioneer Coffee",
        desc: "47% Increase in Customer Retention: A beloved local coffee chain rediscovers growth through digital transformation and community engagement.",
      },
      {
        image: require("./images/1108 (1).png"),
        image_mobile: require("./images/1108mobile (1).png"),
        title: "CloudSecure",
        desc: "300% Revenue Growth Helping a cybersecurity startup break into the enterprise market through strategic B2B positioning.",
      },
      {
        image: require("./images/1108 (6).png"),
        image_mobile: require("./images/1108mobile (3).png"),
        title: "GreenLife Essentials",
        desc: "215% Holiday Sales Increase E-commerce retailer transforms seasonal performance with data-driven multi-channel campaign.",
      },
      {
        image: require("./images/1108 (8).png"),
        image_mobile: require("./images/1108mobile (6).png"),
        title: "FitFlex App",
        desc: "1M+ Downloads in 90 Days Mobile fitness startup achieves explosive growth through viral social media strategy and influencer partnerships.",
      },
      {
        image: require("./images/1108 (9).png"),
        image_mobile: require("./images/1108mobile (4).png"),
        title: "Harvest Fresh",
        desc: "85% Brand Awareness Increase Regional grocery chain transforms from traditional retailer to omnichannel leader with innovative digital strategy.",
      },
    ],
    blog: [
      {
        image: require("./images/1108 (4).png"),
        image_mobile: require("./images/1108mobile (5).png"),
        title: "Engaging Gen Z through Social Media Marketing",
        desc: "Discover why traditional marketing falls flat with Gen Z and learn actionable strategies to authentically connect with this digital-native generation across TikTok, Instagram, and emerging platforms.",
      },
      {
        image: require("./images/1108 (3).png"),
        image_mobile: require("./images/1108mobile (2).png"),
        title: "Consumer Behavior in Online vs. Offline Shopping",
        desc: "Dive into the psychology behind why customers make different choices online versus in-store, and how businesses can optimize both channels for maximum engagement and sales.",
      },
      {
        image: require("./images/1108 (7).png"),
        image_mobile: require("./images/1108mobile (7).png"),
        title: "Neuromarketing: Exploring the Consumer's Mind",
        desc: "Uncover how brands use neuroscience to decode consumer decisions, from eye-tracking studies to brain imaging, and what it means for the future of marketing.",
      },
      {
        image: require("./images/1108 (2).png"),
        image_mobile: require("./images/1108mobile (9).png"),
        title: "The Rise of Voice Commerce: Marketing in an Audio-First World",
        desc: "Explore how voice assistants are transforming shopping habits and learn why optimizing for voice search and audio content is becoming crucial for modern businesses.",
      },
      {
        image: require("./images/1108 (10).png"),
        image_mobile: require("./images/1108mobile (10).png"),
        title: "Sustainable Marketing: Building Brands That Matter",
        desc: "Learn how purpose-driven marketing can boost both profits and planet health, with real examples of brands successfully balancing sustainability with business growth.",
      },
    ],
  });
  const [pageX, setPageX] = useState(0);
  const [pageX_2, setPageX_2] = useState(0);
  const boxByOurWork = useRef(null);
  const boxFullByOurWork = useRef(null);
  const [listWidth_1, setListWidth_1] = useState(0);
  const boxByBlog = useRef(null);
  const boxFullByBlog = useRef(null);
  const [listWidth_2, setListWidth_2] = useState(0);
  const [width, setWidth] = useState(0);
  const [width_2, setWidth_2] = useState(0);
  const [form] = Form.useForm();
  const [shadow, setShadow] = useState(false);


  const handlePrev = () => {
    if (pageX >= 0) return;
    setPageX(pageX + itemWidth_1);

    // if (!current) return
    // setCurrent(current - 1)
    // let el, rect
    // if ((el = boxFullByOurWork.current.children[current]) === void 0) return
    // rect = el.getBoundingClientRect();
    // // if (rect.left < itemWidth_1) {
    // if (rect.left - left < itemWidth_1) {
    //   setPageX(pageX + itemWidth_1);
    // }
  };

  const handleNext = () => {
    let more = listWidth_1 + pageX // 列表未滚动到的区域
    setPageX(pageX - itemWidth_1);
    if (more < window.innerWidth /* 未滚动到的区域不足一个浏览器窗口宽度 */ && text.our_work.length < 99) {
      setText({
        ...text,
        our_work: [...text.our_work, ...text.our_work.slice(0, 5)]
      })
    }

    // setCurrent(current !== null ? current + 1 : 0)

    // let el, rect
    // if ((el = boxFullByOurWork.current.children[current]) === void 0) return
    // rect = el.getBoundingClientRect();
    // // if (window.innerWidth - rect.right < itemWidth_1) {
    // if (ourWork.current.offsetWidth - rect.right + left < itemWidth_1) {
    //   setPageX(pageX - itemWidth_1);
    // }
  };


  const handlePrev_2 = () => {

    if (pageX_2 >= 0) return;
    setPageX_2(pageX_2 + itemWidth_2);

    // if (!current_2) return
    // setCurrent_2(current_2 - 1)
    // let el, rect
    // if ((el = boxFullByBlog.current.children[current_2]) === void 0) return
    // rect = el.getBoundingClientRect();
    // // if (rect.left < itemWidth_2) {
    // if (rect.left - left < itemWidth_2) {
    //   setPageX_2(pageX_2 + itemWidth_2);
    // }
  };


  const handleNext_2 = () => {
    let more = listWidth_2 + pageX_2 // 列表未滚动到的区域

    setPageX_2(pageX_2 - itemWidth_2);
    if (more < window.innerWidth && text.blog.length < 99) {
      setText({
        ...text,
        blog: [...text.blog, ...text.blog.slice(0, 5)]
      })
    }

    // setCurrent_2(current_2 !== null ? current_2 + 1 : 0)
    // let el, rect
    // if ((el = boxFullByBlog.current.children[current_2]) === void 0) return
    // rect = el.getBoundingClientRect();
    // // if (window.innerWidth - rect.right < itemWidth_2) {
    // if (blog.current.offsetWidth - rect.right + left < itemWidth_2) {
    //   setPageX_2(pageX_2 - itemWidth_2);
    // }
  };


  const onFinish = (values) => {
    console.log("Finish:", values);
    form.resetFields()
    // steven@tundatech.com
    const { firstName, lastName, email, phone, website = "", message } = values
    let tmp = `
      First name: ${firstName}%0A
      Last name: ${lastName}%0A
      Email: ${email}%0A
      Phone number: ${phone}%0A
      Do you have a website?: ${website}%0A
      Your message: ${message}%0A
    `
    
    // window.open('mailto:steven@tundatech.com?body=' + tmp, '_self');
    Modal.info({ title: "The message has submitted. Thank you!",footer: null, icon:null, style: { top: "40vh" }, maskClosable: true})
    
  };

  const handleSubscribe = () => {
    setEmail("")
  }

  const handleShowMore = () => {
    setScrollY(document.documentElement.scrollTop)
    setShow(true)
    // window.scrollTo
  }


  /* 处理顶栏阴影 */
  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (window.scrollY > 0) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    });
  }, [])


  /* 处理数据跳动 */
  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (first !== false) { // 如果是首次进入则开启计数
        if (isElementInViewport(countToEl.current)) {
          setFirst(false)
          setShowCountTo(true)
        }
      }
    })
  }, [])

  /* work & blog 的切换卡片效果 */
  useEffect(() => {
    /* 获取内容主体距离浏览器容器左侧的距离 */
    let theleft
    setLeft(theleft = ourWork.current.getBoundingClientRect().left)
    // setPageX(theleft)
    // setPageX_2(theleft)

    if (isMobile()) {
      setCurrent(0)
      setCurrent_2(0)
    }

    setText(produce(({ our_work, blog }) => {
      our_work.push(...our_work, ...our_work);
      blog.push(...blog, ...blog);
    }))

    setListWidth_1(boxFullByOurWork.current.offsetWidth);
    setListWidth_2(boxFullByBlog.current.offsetWidth);


    setItemWidth_1(getItemWidth(boxFullByOurWork.current));
    setItemWidth_2(getItemWidth(boxFullByBlog.current));
  }, []);

  // useEffect(() => {
  //   if (current === text.our_work.length - 5 && text.our_work.length < 99) {
  //     setText({
  //       ...text,
  //       our_work: [...text.our_work, ...text.our_work.slice(0, 5)]
  //     })
  //   }
  // }, [current])

  // useEffect(() => {
  //   if (current_2 === text.blog.length - 5 && text.blog.length < 99) {
  //     setText({
  //       ...text,
  //       blog: [...text.blog, ...text.blog.slice(0, 5)]
  //     })
  //   }
  // }, [current_2])

  useEffect(() => {
    if (show === false) {
      window.scrollTo(0, scrollY)
    }
  }, [show])

  return (
    <div className={"App" + (show ? " h-screen overflow-y-hidden" : "")}>
      <header
        className={
          "sticky left-0 top-0 z-40 bg-[#ffffff]" +
          (shadow ? " shadow-[0px_3px_20px_0px_#0000000D;]" : "")
        }
      >
        <div className="flex justify-between items-center w-[3.9rem] md:w-auto md:max-w-[1920px] md:min-w-[1024px] h-[0.68rem] md:h-[112px] pl-4 md:pl-[100px] pr-4 md:pr-[100px] mb-2.5 md:mb-0 md:mr-auto md:ml-auto">
          <img
            src={Logo}
            alt="Logo"
            className="logo flex-[0_0_0.78rem] h-[.54rem] md:w-[107px] md:h-[73px] md:flex:none"
          />
          <ul className="flex justify-between items-center hide-mobile ml-auto mr-auto w-[541px]">
            <li className="hover:font-extrabold">
              <a href="#home">Home</a>
            </li>
            <li className="hover:font-extrabold">
              <a href="#about_us">About Us</a>
            </li>
            <li className="hover:font-extrabold">
              <a href="#our_services">Our Services</a>
            </li>
            <li className="hover:font-extrabold">
              <a href="#our_work">Our Work</a>
            </li>
            <li className="hover:font-extrabold">
              <a href="#blog">Blog</a>
            </li>
          </ul>
          <More className="hide-desktop w-[.28rem] h-[.28rem] mr-3 ml-auto" onClick={handleShowMore}></More>
          <a href="#contact_us">
            <img
              src={process.env.PUBLIC_URL + "/menu.png"}
              alt=""
              className="w-[0.85rem] h-[0.48rem] hide-desktop zoomIn"
            />
          </a>
          <div className="rightBox hide-mobile">
            <a className="iBtn" href="#contact_us">
              Schedule a FREE Consultation
            </a>
          </div>
        </div>
      </header>
      <main className="flex flex-col">
        <div
          id="home"
          className="home area relative pb-[0.9rem] md:pb-0 md:flex-1 md:flex md:justify-between md:items-start"
        >
          <div className="leftBox text-[0.47rem]  md:flex-[0_0_calc(637/1240*100%)] md:text-[63px]">
            <p className="title leading-[.64rem] md:leading-[1.3em]">
              Save time and generate results with marketing that{" "}
              <span className="gradientText text-[0.47rem] md:text-[60px]">
                actually works.
              </span>
            </p>
            <p className="subtitle mt-5 md:w-[577px]">
              Is your brand's message cutting through and making a real impact?
              At Mapped Verse Labs, we don't just help your voice be heard—we
              ensure it leaves a lasting impression.
            </p>
            <a
              href="#contact_us"
              className="hide-mobile talk w-[3.5rem] md:w-[284px] leading-[0.58rem] md:leading-[58px] iBtn"
            >
              Let's talk
            </a>
            <a
              href="#contact_us"
              className="hide-desktop talk w-[3.5rem] md:w-[284px] leading-[0.58rem] md:leading-[58px] iBtn2 absolute bottom-0 left-[50%] translate-x-[-50%]"
            >
              Let's talk
            </a>
          </div>
          <div className="imgGroup  md:flex-[0_0_calc(558/1240*100%)] md:w-[calc(558/1240*100%)] md:ml-auto mt-6 md:mt-0 md:flex md:flex-wrap">
            <img
              src={require("./images/image 671.png")}
              alt=""
              className="row inline-block md:w-full rounded-[24px]"
            />
            <img
              src={require("./images/image 672.png")}
              alt=""
              className="left inline-block md:mt-6 md:max-w-[calc(339/558*100%)] md:flex-[0_0_calc(339/558*100%)] rounded-[24px]"
            />
            <img
              src={require("./images/image.png")}
              alt=""
              className="right inline-block md:ml-auto md:mt-6 md:max-w-[calc(195/558*100%)] md:flex-[0_0_calc(195/558*100%)] rounded-[24px]"
            />
          </div>
        </div>
        <div id="about_us" className="aboutUs area">
          <div className="box-1 areaTitle gradientText">About Us</div>
          <div className="box-2 areaSubTitle mt-5">
            Mapped Verse Labs is your trusted navigator in the ever-evolving
            landscape of modern marketing.
          </div>
          <div className="md:flex md:justify-between">
            <div className="box-3 md:flex-[0_0_calc(608/1240*100%)]">
              <img
                className="hide-mobile"
                src={require("./images/khc.png")}
                alt=""
              />
              <img
                className="hide-desktop"
                src={require("./images/ggg.png")}
                alt=""
              />
            </div>
            <div className="box-4 md:flex-[0_0_calc(558/1240*100%)]">
              <p>
                At Mapped Verse Labs, we chart new territories in marketing. Our
                team of innovative strategists, creatives, and data analysts
                work in harmony to craft bespoke campaigns that resonate with
                your audience and drive measurable results.
              </p>
              <p>
                With a blend of cutting-edge technology and human insight, we
                map out the unique contours of your brand's story, creating
                immersive experiences that captivate and convert.
              </p>
            </div>
          </div>
          <div className="box-5" ref={countToEl}>
            <div className="bl">
              <span className="num">{showCountTo ? <CountTo start="0" end="200"></CountTo> : "0"}+</span>
              <div className="text">Satisfied Clients</div>
            </div>
            <div className="bl">
              <span className="num">{showCountTo ? <CountTo start="0" end="80"></CountTo> : "0"}+</span>
              <div className="text">Expert Marketing team</div>
            </div>
            <div className="bl">
              <span className="num">{showCountTo ? <CountTo start="0" end="20"></CountTo> : "0"}+</span>
              <div className="text">Track Record</div>
            </div>
          </div>
          <div className="box-6">
            <a href="#contact_us" className="talk iBtn">
              Work with Us
            </a>
          </div>
        </div>
        <div className="other area mb-[60px] md:mb-0">
          <div className="title">
            Working with people like you, trusted by organizations like these...
          </div>
          <ul>
            <li>
              <img src={require("./images/20241108162911.png")} alt="" />
            </li>
            <li>
              <img src={require("./images/Mask group-1.png")} alt="" />
            </li>
            <li>
              <img src={require("./images/Mask group-2.png")} alt="" />
            </li>
            <li>
              <img src={require("./images/Mask group-3.png")} alt="" />
            </li>
          </ul>
        </div>
        <div className="banner w-full md:mt-0">
          <div className="area md:flex md:justify-between">
            <div className="title md:flex-[0_0_calc(505/1240*100%)]">
              Not just marketing, but a full-potential service.
            </div>
            <div className="banner-right md:flex-[0_0_calc(495/1240*100%)]">
              <div className="desc">
                Marketing doesn't have to be complicated. We're here to clear
                the noise, guiding you through each step—whether you're
                kickstarting your journey or refining your winning strategy.
                Let's make marketing work for you.
              </div>
              <a href="#contact_us" className="zoomIn">
                <div className="gradientText">Schedule a FREE Consultation</div>
              </a>
            </div>
          </div>
        </div>
        <div className="qna area">
          <div className="qna-box">
            <img
              src={require("./images/Frame 118.png")}
              alt=""
              className="qna-img"
            />
            <div className="qna-title text-2xl text-[#1a1a1a] leading-10 font-semibold ">
              Feeling unsure where to begin?
            </div>
            <div className="qna-subtitle text-xl md:text-[22px] leading-8 text-[#666666] font-medium">
              We've got you covered with a personalized strategy built from the
              ground up, so you can start strong and stay ahead.
            </div>
          </div>
          <div className="qna-box">
            <img
              src={require("./images/Frame 119.png")}
              alt=""
              className="qna-img"
            />
            <div className="qna-title text-2xl text-[#1a1a1a] leading-10 font-semibold ">
              Love to stay hands-on?
            </div>
            <div className="qna-subtitle text-xl md:text-[22px] leading-8 text-[#666666] font-medium">
              Your insights are invaluable. Together, we'll craft a strategy
              that reflects your vision and amplifies your business's unique
              voice.
            </div>
          </div>
          <div className="qna-box">
            <img
              src={require("./images/Frame 120.png")}
              alt=""
              className="qna-img"
            />
            <div className="qna-title text-2xl text-[#1a1a1a] leading-10 font-semibold ">
              Prefer to step back?
            </div>
            <div className="qna-subtitle text-xl md:text-[22px] leading-8 text-[#666666] font-medium">
              Relax—our team will take the reins, delivering seamless results
              while keeping you informed every step of the way.
            </div>
          </div>
        </div>
        <div id="our_services" className="ourServices area">
          <div className="areaTitle">Our Services</div>
          <div className="areaSubTitle mt-5">
            We're here to help you with...
          </div>
          <div className="mt-6 md:mt-10 md:flex md:justify-between md:items-start">
            <img
              src={require("./images/nac.png")}
              alt=""
              className="w-[3.58rem] h-[4.86rem] md:flex-[0_0_calc(351/1240*100%)] md:h-auto inline-block align-top"
            />
            <ul className="w-[3.58rem] md:flex-[0_0_calc(843/1240*100%)] md:flex md:flex-wrap inline-block md:mr-[-20px] md:mb-[-64px] align-top">
              {text.our_services.list_1.map((v) => (
                <li
                  key={v.title}
                  className="inline-block mt-6 md:mt-0 md:mr-[calc(20/843*100%)] w-[3.58rem] flex:none md:flex-[0_0_calc(260/843*100%)] md:mb-[64px]"
                >
                  <div className="text-2xl text-[#1a1a1a] leading-10 font-semibold md:whitespace-pre">
                    {v.title}
                  </div>
                  <div className="text-lg md:text-xl text-[#666666] leading-7 font-medium">
                    {v.desc}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="areaSubTitle mt-[.48rem] md:mt-[80px] text-right">
            ...so we can get your brand out there
          </div>
          <div className="md:mt-10 md:flex md:justify-between md:items-start">
            <ul className="w-[3.58rem] md:flex-[0_0_calc(843/1240*100%)] md:flex md:flex-wrap inline-block md:mr-[-20px] md:mb-[-64px] align-top">
              {text.our_services.list_2.map((v) => (
                <li
                  key={v.title}
                  className="inline-block mt-6 md:mt-0 md:mr-[calc(20/843*100%)] w-[3.58rem] flex:none md:flex-[0_0_calc(260/843*100%)] md:mb-[64px]"
                >
                  <div className="text-2xl text-[#1a1a1a] leading-10 font-semibold md:whitespace-pre">
                    {v.title}
                  </div>
                  <div className="text-lg md:text-xl text-[#666666] leading-7 font-medium">
                    {v.desc}
                  </div>
                </li>
              ))}
            </ul>
            <img
              src={require("./images/bk.png")}
              alt=""
              className="mt-6 md:mt-0 md:ml-[47px] w-[3.58rem] h-[4.86rem] md:flex-[0_0_calc(351/1240*100%)] md:h-auto inline-block align-top"
            />
          </div>
          <div className="text-center">
            <a
              href="#contact_us"
              className="iBtn mt-7 md:mt-[44px] w-[3.49rem] md:w-[226px] h-[.58rem] md:h-[58px] leading-[.58rem] md:leading-[58px]"
            >
              Work with Us
            </a>
          </div>
        </div>
        <div className="step area md:flex md:justify-between">
          <div className="md:flex-[0_0_calc(561/1240*100%)] inline-block align-top">
            <div className="areaSubTitle">
              Wherever your target market is, there you are
            </div>
            <div className="text-xl md:text-[22px] leading-8 text-[#666666] mt-6 md:mt-10">
              Mapped Verse Labs ensures you're present at every critical
              touchpoint in your customer's journey, keeping your business
              top-of-mind.
            </div>
            <img
              src={require("./images/lana.png")}
              alt=""
              className="hide-mobile mt-5 w-[3.58rem] md:w-full h-[3.74rem] md:h-auto"
            />
            <img
              src={require("./images/nlancl.png")}
              alt=""
              className="hide-desktop mt-5 w-[3.58rem] md:w-full h-[3.74rem] md:h-auto"
            />
          </div>
          <div className="md:flex-[0_0_calc(595/1240*100%)] mt-6 md:mt-0 ml-0 md:ml-[84px] mr-auto md:mr-0 inline-block align-top">
            {text.step.map((v, i, arr) => (
              <div className="item pl-[.92rem] md:pl-[92px] relative pb-[.36rem] md:pb-[36px]">
                <div className="step-circle iBtn">{i + 1}</div>
                {i === arr.length - 1 ? null : (
                  <div className="step-line iBtn"></div>
                )}
                <div className="text-2xl leading-10 text-[#1a1a1a]">
                  {v.title}
                </div>
                <div className="text-lg md:text-xl leading-7 text-[#666666] md:h-[84px]">
                  {v.desc}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div id="our_work" className="ourWork area relative">
          <div className="areaTitle">Our Work</div>
          <div className="areaSubTitle mt-5 text-center">
            Innovating the Future, One Project at a Time
          </div>
          <div className="w-auto md:max-w-[1018px] mx-auto text-center text-xl leading-8 text-[#666666] mt-6 md:mt-10">
            At Mapped Verse Labs, our journey is defined by groundbreaking
            projects that push the boundaries of technology and creativity.
            Specializing in full-service marketing, we partner with visionary
            brands to deliver solutions that meet their needs and exceed
            expectations. Explore our notable projects that highlight our
            commitment to excellence and our drive to turn ideas into impactful
            realities.
          </div>
          <div ref={ourWork} className="h-[5.03rem] md:h-[541px] relative mt-5 md:mt-10 overflow-hidden">
            <div
              className="scrollBox h-full py-[.2rem] md:py-[20px] overflow-hidden"
            // style={{ width }}
            // ref={boxByOurWork}
            // style={{ left: -left, top: 0 }}
            >
              <div
                className="scrollFull absolute mt-0 top-[.2rem] md:top-[20px] whitespace-nowrap transition-all h-[calc(100%-.4rem)] md:h-[calc(100%-40px)]"
                style={{ left: pageX }}
                ref={boxFullByOurWork}
              >
                {text.our_work.map((v, idx) => (
                  !isMobile() ?
                    <div
                      className={(current === idx ? "scale-[1.05] ml-6 mr-6 " : "ml-3 mr-3 ") + "transition-all md:hover:scale-[1.05] rounded-[.32rem] md:rounded-[32px] w-[3.28rem] h-full md:w-[328px] relative overflow-hidden inline-block"}
                      key={idx}
                    >
                      <img src={v.image_mobile} className="hide-mobile w-full" alt="" />
                      <div className="themeBgColor p-4 absolute bottom-0 md:h-[218px]">
                        <div className="text-white text-2xl">{v.title}</div>
                        <div className={(v.title === "Pioneer Coffee" ? "md:text-[19px] " : "md:text-xl " )+  "text-white text-lg pt-1 whitespace-normal text-ellipsis line-clamp-5"}>
                          {v.desc}
                        </div>
                      </div>
                    </div>
                    // ↑ PC端 ↓ 移动端 
                    : <div
                      className="mr-6 transition-all md:hover:scale-[1.05] rounded-[.32rem] md:rounded-[32px] w-[3.28rem] h-full relative overflow-hidden inline-block"
                      key={idx}
                    >
                      <img src={v.image} className="hide-mobile w-full" alt="" />
                      <img
                        src={v.image_mobile}
                        className="hide-desktop w-full"
                        alt=""
                      />
                      <div className="themeBgColor p-4 absolute bottom-0 h-auto min-h-[1.80rem] md:h-[160px]">
                        <div className="text-white text-2xl">{v.title}</div>
                        <div className="text-white text-lg md:text-xl pt-1 whitespace-normal text-ellipsis line-clamp-5">
                          {v.desc}
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
          </div>
          <div className="arrowBox flex justify-center mt-10 text-xl text-[#999999]">
            <div
              className="cursor-pointer prev table-cell text-right align-middle select-none w-[1.14rem] md:w-[114px] leading-[.17rem] md:leading-[17px] mr-6"
              onClick={handlePrev}
            >
              <i className="iconfont icon-2301jiantou2 mr-4"></i>
              Prev
            </div>
            <div
              className="cursor-pointer next table-cell align-middle select-none w-[1.14rem] md:w-[114px] leading-[.17rem] md:leading-[17px] ml-6"
              onClick={handleNext}
            >
              Next
              <i className="iconfont icon-arrow-right2-copy ml-4"></i>
            </div>
          </div>
        </div>
        <div id="blog" className="blog area z-10 relative">
          <div className="areaTitle">Blog</div>
          <div className="areaSubTitle mt-5 text-center">Insights Hub</div>
          <div className="w-auto md:max-w-[1018px] mx-auto text-center text-xl leading-8 text-[#666666] mt-6 md:mt-10">
            Join us on a dynamic exploration of how innovation shapes our world. Get inspired by the bold ideas and creative solutions that are redefining the future.
          </div>
          <div ref={blog} className="overflow-hidden h-[5.76rem] md:h-[554px] mt-10 relative">

            <div
              className="scrollBox h-full py-[.1rem] md:py-[10px] overflow-hidden"
            // ref={boxByBlog}
            // style={{ width }}
            // style={{ left: -left, top: 0 }}
            >
              <div
                className="h-[calc(100%-.2rem)] md:h-[calc(100%-20px)] scrollFull transition-all absolute mt-0 top-[.1rem] md:top-[10px] whitespace-nowrap"
                style={{ left: pageX_2 }}
                ref={boxFullByBlog}
              >
                {text.blog.map((v, idx) => (
                  !isMobile() ?
                    <div key={idx} className={(current_2 === idx ? "scale-[1.05] ml-6 mr-6 " : "ml-3 mr-3 ") + "transition-all hover:scale-[1.05] w-[3.28rem] md:w-[397px] h-full inline-block overflow-hidden"}>
                      <img
                        src={v.image}
                        className="w-[3.28rem] md:w-[397px] h-[2.98rem] md:h-[298px] rounded-[.32rem] md:rounded-[32px]"
                        alt=""
                      />
                      <div className="text-2xl leading-10 font-semibold text-[#1a1a1a] mt-3 whitespace-normal line-clamp-2 text-ellipsis">
                        {v.title}
                      </div>
                      <div className={(v.title.indexOf("Engaging") === 0 ? "md:text-[19px] " : "md:text-xl ") + "text-lg leading-7 text-[#666666] mt-1 whitespace-normal md:line-clamp-5 text-ellipsis"}>
                        {v.desc}
                      </div>
                    </div>
                    // ↑ PC端 ↓ 移动端 
                    : <div key={idx} className="mr-6 transition-all w-[3.28rem] md:w-[397px] h-full inline-block overflow-hidden">
                      <img
                        src={v.image_mobile}
                        className="w-[3.28rem] md:w-[397px] h-[2.98rem] md:h-[298px] rounded-[.32rem] md:rounded-[32px]"
                        alt=""
                      />
                      <div className="text-2xl leading-10 font-semibold text-[#1a1a1a] mt-3 whitespace-normal line-clamp-2 text-ellipsis">
                        {v.title}
                      </div>
                      <div className="text-lg leading-7 text-[#666666] mt-1 whitespace-normal line-clamp-6 text-ellipsis">
                        {v.desc}
                      </div>
                    </div>
                ))}
              </div>
            </div>
          </div>
          <div className="arrowBox flex justify-center mt-10 text-xl text-[#999999]">
            <div
              className="cursor-pointer prev table-cell text-right align-middle select-none w-[1.14rem] md:w-[114px] leading-[.17rem] md:leading-[17px] mr-6"
              onClick={handlePrev_2}
            >
              <i className="iconfont icon-2301jiantou2 mr-4"></i>
              Prev
            </div>
            <div
              className="cursor-pointer next table-cell align-middle select-none w-[1.14rem] md:w-[114px] leading-[.17rem] md:leading-[17px] ml-6"
              onClick={handleNext_2}
            >
              Next
              <i className="iconfont icon-arrow-right2-copy ml-4"></i>
            </div>
          </div>
        </div>
        <div id="contact_us" className="contactUs area">
          <div className="areaTitle">Contact Us</div>
          <div className="mt-5 md:whitespace-nowrap md:flex md:justify-between">
            <div className="leftBox inline-block align-top w-[3.58rem] md:flex-[0_0_calc(506/1240*100%)] whitespace-normal">
              <div className="areaSubTitle">
                Your Competition Isn't Waiting. Why Should You?
              </div>
              <div className="mt-10 text-xl md:text-[22px] leading-8 text-[#666666]">
                We'd love to help you get on the right path. Share a few details
                about your business, and we'll prepare a custom growth strategy
                before our call.
              </div>
            </div>
            <div className="rightBox ml-0 inline-block w=[3.58rem] md:flex-[0_0_calc(608/1240*100%)]">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={onFinish}
              >
                <div className="w-[3.58rem] md:flex-[0_0_calc(292/608*100%)] mt-6 md:mt-0">
                  <div className="w-full mb-1 text-xl leading-8 font-semibold">
                    First name*
                  </div>
                  <Form.Item
                    style={{
                      width: "100%",
                      height: "48px",
                      marginInlineEnd: 0,
                    }}
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Not null!",
                      },
                    ]}
                  >
                    <Input className="input" />
                  </Form.Item>
                </div>
                <div className="w-[3.58rem] md:flex-[0_0_calc(292/608*100%)] mt-3 md:mt-0">
                  <div className="w-full mb-1 text-xl leading-8 font-semibold md:ml-6">
                    Last name*
                  </div>
                  <Form.Item
                    className="ml-0 md:ml-6 w-full me-0"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Not null!",
                      },
                    ]}
                  >
                    <Input className="input" />
                  </Form.Item>
                </div>
                <div className="w-[3.58rem] md:flex-[0_0_calc(292/608*100%)] mt-3 md:mt-0">
                  <div className="w-full mb-1 text-xl leading-8 md:mt-5 font-semibold">
                    Email*
                  </div>
                  <Form.Item
                    style={{
                      width: "100%",
                      height: "48px",
                      marginInlineEnd: 0,
                    }}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Not null!",
                      },
                      {
                        type: "email",
                        message: "Invalid value!",
                      },
                    ]}
                  >
                    <Input className="input" />
                  </Form.Item>
                </div>
                <div className="w-[3.58rem] md:flex-[0_0_calc(292/608*100%)] mt-3 md:mt-0">
                  <div className="w-full mb-1 text-xl leading-8 md:mt-5 font-semibold md:ml-6">
                    Phone number*
                  </div>

                  <Form.Item
                    className="ml-0 md:ml-6 w-full me-0"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Not null!",
                      },
                      {
                        type: "digits",
                        message: "Invalid value!",
                      },
                    ]}
                  >
                    <Input type="number" className="input" />
                  </Form.Item>
                </div>
                <div className="w-[3.58rem] md:w-full mb-1 text-xl leading-8 mt-3 md:mt-5 font-semibold">
                  Do you have a website?
                </div>
                <Form.Item
                  className="w-full h-[.48rem] md:h-[48px] me-0"
                  name="website"
                >
                  <Input className="input" placeholder="If yes, please write the link here." />
                </Form.Item>
                <div className="w-[3.58rem] md:w-full mb-1 text-xl leading-8 mt-3 md:mt-5 font-semibold">
                  Your message*
                </div>
                <Form.Item
                  name="message"
                  className="w-full me-0"
                  rules={[
                    {
                      required: true,
                      message: "Not null!",
                    },
                  ]}
                >
                  <Input.TextArea rows={3} className="textarea" />
                </Form.Item>
              </Form>
              <div onClick={() => { form.submit() }} className="mt-6 md:mt-5 w-[3.58rem] md:w-[203px] h-[.58rem] md:h-[58px] leading-[.58rem] md:leading-[58px] iBtn">
                Submit Now
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="footer h-[4.74rem] md:h-[474px] mt-0 md:mt-[80px] pt-[.80rem] md:pt-[80px]">
        <div className="text-[.36rem] leading-[.52rem] md:text-[63px] w-[358px] md:leading-[84px]  text-[#ffffff] md:w-[792px] md:h-[168px] mx-auto text-center font-extrabold">
          Get free access to expert marketing insights.
        </div>
        <div className="mt-6 md:mt-0  md:mt-[43px] w-[358px] md:w-[792px] mx-auto flex flex-wrap justify-center">
          <div className="input text-sm w-[3.58rem] leading-[.40rem] md:leading-[40px]  md:w-[396px] md:ml-auto">
            <Input
              value={email}
              onInput={(e) => setEmail(e.target.value)}
              style={{ lineHeight: "inherit", width: "100%", border: 0 }}
              placeholder="Your email address"
            />
          </div>
          <div className="mx-auto mt-6 md:mt-0 md:ml-4 w-[1.53rem] md:w-[153px] h-[.48rem] md:h-[48px] leading-[.48rem] md:leading-[48px] bg-white flex justify-center items-center rounded-[9.99rem] md:rounded-[999px] zoomIn">
            <a href="javascript:;" onClick={handleSubscribe} className="gradientText text-xl">
              Subscribe
            </a>
          </div>
        </div>
      </footer>
      { show && <div className="mask z-50 absolute top-0 left-0 right-0 bottom-0 bg-[#ffffff]">
        <div className="flex justify-between items-center w-[3.9rem] md:w-auto md:max-w-[1920px] md:min-w-[1024px] h-[0.68rem] md:h-[112px] pl-4 md:pl-[100px] pr-4 md:pr-[100px] mb-2.5 md:mb-0 md:mr-auto md:ml-auto">
          <img
            src={Logo}
            alt="Logo"
            className="logo flex-[0_0_0.78rem] h-[.54rem] md:w-[107px] md:h-[73px] md:flex:none"
            onClick={() => { setShow(true) }}
          />
          <CloseOutlined className="w-7 h-7" onClick={() => { setShow(false) }} />
        </div>
        <ul className="" onClick={() => { setShow(false) }}>
          <li className="flex text-[.22rem] leading-[.34rem] py-[.24rem] pl-4">
            <a href="#home" className="w-full">
              Home
            </a>
          </li>
          <li className="flex text-[.22rem] leading-[.34rem] py-[.24rem] pl-4">
            <a href="#about_us" className="w-full">
              About Us
            </a>
          </li>
          <li className="flex text-[.22rem] leading-[.34rem] py-[.24rem] pl-4">
            <a href="#our_services" className="w-full">
              Our Services
            </a>
          </li>
          <li className="flex text-[.22rem] leading-[.34rem] py-[.24rem] pl-4">
            <a href="#our_work" className="w-full">
              Our Work
            </a>
          </li>
          <li className="flex text-[.22rem] leading-[.34rem] py-[.24rem] pl-4">
            <a href="#blog" className="w-full">
              Blog
            </a>
          </li>
        </ul>
      </div> }
    </div>
  );
}

export default App;
