import is_mobile from "is-mobile";

const isElementInViewport = (el) => {
    if (el === null) return
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

const isMobile = window.location.href.split("?")[1] ? ()=>false : is_mobile

export {
    isElementInViewport,
    isMobile
}